<template lang="pug">
    div
        loader(v-if="$root.loader")
        template(v-else)
            v-snackbar(
                :timeout="3000",
                :top="true"
                :right="true"
                :color="alertColor"
                v-model="alertMsj")
                    | {{ alertText }}
                    v-btn(flat, dark, @click.native="alertMsj = false") {{$t('cerrar')}}
            header-component
                v-btn.no-margin(v-if="tab == 1" absolute dark fab top right color='primary' @click.stop="drawerRight = !drawerRight")
                    v-icon search
            v-navigation-drawer.filters.grey.darken-3(v-if="tab == 1" fixed v-model="drawerRight" right app width="246")
                v-form(@submit.prevent="")
                    .filters-content
                        v-select.mb-3(
                            v-model="filter.status"
                            :items='statusList'
                            :label='$t("Estado")' 
                            item-text="name"
                            item-value="id"
                            append-icon="keyboard_arrow_down"
                            autocomplete
                            dark
                            color="white"
                        )
                        .btns.btns-right
                            v-btn(@click="cleanFilter" color="primary") {{$t('Limpiar')}}
            v-content
                v-container.grid-list-xl(fluid)
                    h1 {{$t('WhatsApp')}}
                    v-tabs(v-model="tab")
                        v-tab {{ $t('Chats de WhatsApp')}}
                        v-tab {{ $t('Envíos segmentados')}}
                    v-tabs-items(v-model="tab")
                        // Chats de WhatsApp
                        v-tab-item(:value="0")
                            template(v-if="preload")
                                h3.my-2 {{$t('Cargando')}}...
                            v-layout(row wrap v-else)
                                v-flex(xs12 sm3)
                                    v-navigation-drawer.message-list-drawer(width="100%" permanent)
                                        .message-list
                                            //v-text-field.search-friend(:label="$t('Buscar chat')")
                                            .my-2.text-xs-center(v-if="loadChats")
                                                v-progress-circular.text-xs-center(:size="24" indeterminate color="primary")
                                            template(v-else)
                                                .not-found.mx-auto.text-xs-center.my-4(v-if="!chats.length")
                                                    h3 {{ $t('No se encontraron chats') }}
                                                v-list.pt-0.mt-2(v-else dense='')
                                                    v-list-tile.cur-p(@click="active = chat,readChat(chat)" :class="{'active': Object.keys(active).length && active._id == chat._id}" v-for="(chat, i) in chats" :key="'cht'+i")
                                                        v-list-tile-avatar
                                                            v-avatar.grey.lighten-4(size='27')
                                                                img(v-if="photoUrl(chat.user.photo).length" :src="photoUrl(chat.user.photo)")
                                                                v-gravatar(v-else :email="chat.user.email")
                                                        v-list-tile-content
                                                            v-list-tile-title 
                                                                b(v-if="!chat.read") {{ chat.user.fullnames }}
                                                                span(v-else) {{ chat.user.fullnames }}
                                                            v-list-tile-sub-title
                                                                b(v-if="!chat.read") {{ chat.user_wp }} - {{ chat.user.network.name }}
                                                                span(v-else) {{ chat.user_wp }} - {{ chat.user.network.name }}
                                                div(v-if="chats.length")
                                                    infinite-loading(:infinite="infiniteChats" ref="infiniteChat")

                                v-flex(xs12 sm9 v-if="chats.length")
                                    template(v-if="!Object.keys(active).length")
                                        .not-found.mx-auto.text-xs-center.my-5
                                            h3 {{ $t('Seleccione un chat') }}
                                    .full-width.message-container(v-else)
                                        h4 {{ active.user.fullnames }}
                                        p {{ active.user_wp }} - {{ active.user.network.name }}
                                        .my-2.text-xs-center(v-if="loadingMsg")
                                            v-progress-circular.text-xs-center(:size="24" indeterminate color="primary")
                                        div.my-4.message-log#message-log(@scroll="fetchMoreMessages")
                                            .not-found.mx-auto.text-xs-center.my-4(v-if="!loadingMsg && !messages.length")
                                                h3 {{ $t('No se encontraron mensajes') }}
                                            div.message.py-2.my-1(:class="{'me': !message.whatsapp }" v-for="(message, i) in messages" :key="'mg'+i")
                                                small(v-if="message.whatsapp")
                                                    v-avatar.mr-2.grey.lighten-4(size='12')
                                                        img(v-if="photoUrl(active.user.photo).length" :src="photoUrl(active.user.photo)")
                                                        v-gravatar(v-else :email="active.user.email")
                                                    | {{ active.user.fullnames }}
                                                small(v-else) {{ $t('Tú') }}
                                                p.mb-1 {{ message.message }}
                                        div.message-box.d-flex(v-if="!loadingMsg")
                                            v-text-field(@keyup.enter="sendMsg" multi-line rows="2" :label="$t('Ingrese un mensaje')" outline v-model="msgtext")
                                            v-btn.pa-3(:loading="loaderSend" :disabled="loaderSend" transition="slide-x-transition" @click="sendMsg" v-if="msg.length" color="primary")
                                                | {{ $t('Enviar') }}
                                                v-icon.ml-2 send
                        // Envíos segmentados
                        v-tab-item(:value="1")
                            template(v-if="preload")
                                h3.my-2 {{$t('Cargando')}}...
                            v-data-table(v-else
                                :items='lists'
                                :headers='headers'
                                :pagination.sync='pagination'
                                :rows-per-page-items="$root.rowsPageItems"
                                :rows-per-page-text="$root.rowsPageText" 
                                item-key='uuid'
                                :total-items="total"
                                :loading="loading")
                                template(slot='items' slot-scope='props')
                                    tr
                                        td {{ props.item.datecreated }}
                                        td {{ props.item.network }}
                                        td {{ props.item.total.total }}
                                        td {{ props.item.total.error }}
                                        td {{ props.item.total.success }}
                                        td {{ statusLabel(props.item.status) }}
                                        td.text-xs-right
                                            v-menu(left)
                                                v-icon(color='primary' dark slot='activator') more_vert
                                                v-list.sheet-menu
                                                    v-list-tile.white--text(@click.native="$refs.manageList.open(props.item)")
                                                        v-list-tile-title {{ $t('Detalle') }}
                                                    v-list-tile.white--text(@click.native="$refs.resend.open(props.item)")
                                                        v-list-tile-title {{ $t('Volver a enviar') }}
                                template(slot="no-data")
                                    span {{ $t('No se encontraron envíos segmentados') }}
                v-btn(v-if="tab == 1" @click.native="$refs.manageList.open({})" fixed dark fab bottom right color='primary')
                    v-icon add
            manage-list(ref="manageList" @success="fetchList")
            resend(ref="resend" @success="fetchList")
</template>

<script>

    import auth from 'mixins/auth'
    import fn from 'mixins/fn'
    import ws from 'mixins/ws'
    import config from 'src/config'
    import ManageList from 'components/whatsapp/ManageList'
    import Resend from 'components/whatsapp/Resend'
    import _ from 'lodash/debounce'
    
    export default {
        mixins: [auth, fn, ws],
        metaInfo() {
            return {
                title: this.$t('WhatsApp')
            }
        },
        data() {
            return {
                // Tabs
                tab: 0,
                preload: true,

                drawerRight : false,
                
                alertMsj    : false,
                alertColor  : '',
                alertText: '',

                // Chats
                chats: [],
                loadChats: false,
                active: {},
                limitChat : 20,
                offsetChat: 0,

                // Envios segmentados
                lists: [],
                total: 0,
                loading: false,
                pagination: {
                    page       : 1,
                    rowsPerPage: 20,
                    descending: true
                },
                headers: [
                    {
                        text: 'Fecha de envío',
                        align: 'left',
                        value: 'datecreated',
                        sortable: false
                    },
                    {
                        text: 'Red',
                        align: 'left',
                        value: 'network',
                        sortable: false
                    },
                    {
                        text: 'Total',
                        align: 'left',
                        value: 'total.total',
                        sortable: false
                    },
                    {
                        text: 'Fallidos',
                        align: 'left',
                        value: 'total.error',
                        sortable: false
                    },
                    {
                        text: 'Enviados',
                        align: 'left',
                        value: 'total.success',
                        sortable: false
                    },
                    {
                        text: 'Estado',
                        align: 'left',
                        value: 'status',
                        sortable: false
                    },
                    {
                        text: 'Opciones',
                        align: 'center',
                        value: '',
                        sortable: false
                    }
                ],
                cancelFilter: null,
                filter: {},
                filterSchema: {
                    status : [],
                    from   : null,
                    to     : null
                },
                statusList: [
                    {
                        name: 'Creado',
                        id: 1
                    },
                    {
                        name: 'En progreso',
                        id: 2
                    },
                    {
                        name: 'Enviado',
                        id: 3
                    }
                ],

                // Mensajes
                loadingMsg : false,
                messages   : [],
                limitLog   : 10,
                offsetLog  : 0,
                loaderSend : false,
                msgtext    : ''
            }
        },
        computed: {
            msg(){
                return this.msgtext.trim()
            },
            me(){
                return this.$root.profile.uuid
            },
            profile(){
                return this.$root.profile
            }
        },
        watch: {
            active() {

                if (!Object.keys(this.active).length) {
                    return
                }

                this.messages = []
                this.offsetLog = 0
                this.fetchMessages()
            },
            filter: {
                handler(){
                    
                    this.preload = true
                    this.total = 0

                    if(this.tab == 1){
                        this.lists = []
                        this.fetchList()
                    }
                },
                deep: true
            },
            pagination: {
                handler () {
                    if(this.tab == 1){
                        this.fetchList()
                    }
                },
                deep: true
            },
            tab(){

                // Chats de WhatsApp
                if(this.tab == 0){
                    this.preload = false
                    this.chats = []
                    this.active = {}
                    this.messages = []
                    this.limitLog = 10
                    this.offsetLog = 0
                    this.limitChat = 20
                    this.offsetChat = 0
                    this.fetchChats()
                }

                // Envíos programados
                if(this.tab == 1){

                    this.preload = true
                    this.total = 0
                    this.lists = []
                    this.fetchList()
                }
            },
            profile: {
                deep: true,
                async handler() {
                    this.connect_ws_whatsapp(this.me, (e) => {

                        let data = JSON.parse(e.data)
                        if (Object.keys(data).length) {

                            // Crear nuevo chat si no existe
                            if (!data.read) {
                                if (!this.chats.find(c => c._id == data._id)) {
                                    this.chats.unshift(data)
                                } else {
                                    let idx = this.chats.findIndex(c => c._id == data._id)
                                    this.chats.splice(idx, 1)
                                    this.chats.unshift(data)
                                }   
                            }

                            // Enviar mensaje a un chat activo
                            if (this.active._id == data._id && data.hasOwnProperty('message') && Object.keys(data.message).length) {
                                this.messages.push(data.message)
                                this.$nextTick(() => {
                                    this.offsetLog += 1
                                    this.resetScroll()
                                })
                            }
                        }
                    })
                }
            }
        },
        methods: {
            fetchMoreMessages(e) {
                if (e.target.scrollTop == 0) {
                    
                    this.offsetLog += this.limitLog
                    this.fetchMessages(true)
                }    
            },
            fetchMessages(scroll = false) {

                this.loadingMsg = true
                this.$api(this, (xhr) => {
                    xhr.get('/v2/whatsapp/chat/messages/', {
                        params: {
                            limit  : this.limitLog,
                            offset: this.offsetLog,
                            user_wp: this.active.user_wp,
                            networkid: this.active.network_id
                        }
                    }).then((r) => {

                        this.loadingMsg = false
                        let data = r.data
                        if(data.response){
                            this.messages = scroll ? [...data.data, ...this.messages] : data.data
                            this.$nextTick(() => {
                                if(!scroll){
                                    this.resetScroll()
                                }
                            })
                        }

                    }).catch(() => {
                        this.loadingMsg = false
                    })
                })
            },
            resetScroll(){

                let log = document.getElementById('message-log')
                log.scrollTop = log.scrollHeight
            },
            sendMsg(e) {

                if(e.shiftKey){
                    return
                }

                if(!this.msg.length){
                    return
                }

                this.loaderSend = true
                this.$api(this, (xhr) => {
                    xhr.post('/v2/whatsapp/conversation/init', this.$qs.stringify({
                        userid: this.active.user.uuid,
                        networkid: this.active.network_id,
                        message : this.msg
                    })).then((r) => {

                        let data    = r.data
                        this.loaderSend = false

                        if (data.response) {
                            
                            this.msgtext = ''
                            this.$nextTick(() => {
                                this.resetScroll()
                            })

                        }else{
                            
                            this.alert  = true
                            this.alertText  = this.$t(data.message)
                            this.alertColor = 'error'
                        }
                    }).catch(() => this.loaderSend = false)
                })
            },
            fetchList: _(function(){

                if(this.cancelFilter != null){
                    this.cancelFilter.cancel()
                }

                let CancelToken   = this.$axios.CancelToken
                this.cancelFilter = CancelToken.source()

                this.loading = true
                this.$api(this, (xhr) => {
                    xhr.get('/v2/whatsapp/conversation/batch', {
                        params: {
                            filter : JSON.stringify(this.filter),
                            limit  : this.pagination.rowsPerPage,
                            offset : (this.pagination.page-1)*this.pagination.rowsPerPage,
                        }
                    }).then((r) => {

                        let data     = r.data
                        this.preload = false
                        this.loading = false

                        if(data.response){

                            this.total = data.data.total
                            this.lists = data.data
                        }
                    }).catch(() => {})
                })

            }, 500),
            statusLabel(id) {
                let label = this.statusList.find(s => s.id == id)
                return label ? label.name : ''
            },
            fetchChats(infinite = false) {

                if(infinite){
                    this.offsetChat += this.limitChat
                }else{
                    this.loadChats = true
                    this.routes = []
                    this.offsetChat = 0
                    this.$refs.infiniteChat && this.$refs.infiniteChat.reset()
                }

                let filter = {
                    limit : this.limitChat,
                    offset: this.offsetChat
                }

                this.$api(this, (xhr) => {
                    xhr.get('/v2/whatsapp/chat', { params: filter }).then((r) => {

                        if(!infinite) this.loadChats = false
                        let data = r.data

                        if(infinite){
                            if(data.response){
                                this.chats = this.chats.concat(data.data)
                                this.$refs.infiniteChat && this.$refs.infiniteChat.loaded()
                            }else{
                                this.$refs.infiniteChat && this.$refs.infiniteChat.complete()
                            }
                        }else{
                            this.chats = (data.response ? data.data : [])
                        }

                    }).catch(() => {
                        this.loadChats = false
                    })
                })
            },
            infiniteChats(){
                setTimeout(() => this.fetchChats(true) , 500)
            },
            photoUrl(photo){

                if(photo != null && photo.length){
                    return config.uploaduser + photo
                }
                return ''
            },
            readChat(chat, active = false) {

                if(!active && chat.read){
                    return
                }

                if (chat.read) {
                    return
                }

                this.$api(this, (xhr) => {
                    xhr.post('/v2/whatsapp/chat/read', this.$qs.stringify({
                        networkid: this.active.network_id,
                        id: this.active._id
                    })).then((r) => {

                        let data = r.data
                        if(data.response){
                            chat.read = true
                        }

                    }).catch(() => {})
                })
            }
        },
        components: {
            ManageList,
            Resend
        }
    }

</script>