<template lang="pug">
    v-layout(row wrap)
        template(v-if="preload")
            h3 {{$t('Cargando')}}..
        template(v-else)
            v-flex(xs12 sm6)
                v-select.mb-3(
                    v-model="model.countryid"
                    :items='countries'
                    item-text="name"
                    item-value="uuid"
                    :label='$t("País")'
                    append-icon="keyboard_arrow_down" 
                    autocomplete
                    :filter="vFilter"
                    @input="model.stateid='',model.cityid='',states=[],cities=[],fetchState()"
                    clearable
                )
            v-flex(xs12 sm6)
                v-select.mb-3(
                    v-model="model.stateid"
                    :items='states'
                    item-text="name"
                    item-value="uuid"
                    :label='$t("Estado/Departamento")'
                    append-icon="keyboard_arrow_down" 
                    autocomplete
                    :filter="vFilter"
                    @input="model.cityid='',cities=[],fetchCity()"
                    clearable
                )
            v-flex(xs12 sm6)
                v-select.mb-3(
                    v-model="model.cityid"
                    :items='cities'
                    item-text="name"
                    item-value="uuid"
                    :label='$t("Ciudad")'
                    append-icon="keyboard_arrow_down" 
                    autocomplete
                    :filter="vFilter"
                    clearable
                )
            v-flex(xs12 sm6)
                v-select.mb-3(
                    v-model="model.gender"
                    :items='gender'
                    item-text="text"
                    item-value="id"
                    :label='$t("Género")'
                    append-icon="keyboard_arrow_down" 
                    autocomplete
                    :filter="vFilter"
                    clearable
                )
            v-flex(xs12 sm6)
                v-select.mb-3(
                    v-model="model.age"
                    :items='ages'
                    item-text="text"
                    item-value="id"
                    :label='$t("Rango de edad")'
                    append-icon="keyboard_arrow_down",
                    :filter="vFilter"
                    autocomplete
                    clearable
                )
            v-flex(xs12)
                .btns.btns-right
                    v-btn(v-if="!preload" color="primary" :loading="loadingBtn" :disabled="loadingBtn" small @click="filterUser") {{ $t('Buscar usuarios') }}  
            v-flex(xs12)
                h4.my-2 {{$t('Listado de usuarios encontrados')}}
                p: i {{ $t('Estos son los usuarios que han sido encontrados según el filtro que elegiste') }}
            v-flex(xs12)
                v-data-table(
                    v-model="selectedUsers"
                    :headers="headers"
                    :items="users"
                    select-all
                    item-key="uuid"
                    :pagination.sync='pagination'
                    :rows-per-page-items="$root.rowsPageItems"
                    :rows-per-page-text="$root.rowsPageText" 
                    :total-items="total"
                    :loading="loading"
                    style="max-height:500px;overflow-x:scroll;"
                )
                    template(slot="items" slot-scope="props")
                        tr(:active="props.selected" @click="props.selected = !props.selected")
                            td
                                v-checkbox(
                                    primary
                                    hide-details
                                    :input-value="props.selected"
                                )
                            td {{ props.item.fullnames }}
                            td {{ props.item.email }}
</template>

<script>

import fn from 'mixins/fn'
export default {
    mixins: [fn],
    props: {
        networkid: String,
        selected: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
            modal     : false,
            preload   : true,
            countries  : [],
            states     : [],
            cities     : [],
            modelSchema: {
                countryid : '',
                stateid   : '',
                cityid    : '',
                gender    : '',
                age       : ''
            },
            model: {},
            loadingBtn: false,
            selectedUsers: [],
            pagination: {
                page: 1,
                rowsPerPage: 100
            },
            users: [],
            loading: false,
            total: 0
        }
    },
    watch: {
        model: {
            deep: true,
            handler() {
                
                if (!this.users.length) {
                    return
                }

                this.users = []
                this.total = 0
                this.pagination.page = 1
            }
        },
        pagination: {
            handler() {
                if (!this.users.length) {
                    return
                }
                this.filterUserPagination()
            },
            deep: true
        },
        selectedUsers() {
            this.$emit('update:selected', this.selectedUsers)
        }
    },
    computed: {
        headers() {
            return [
                {
                    text: this.$t('Nombres'),
                    value: 'fullnames',
                    sortable: false
                },
                {
                    text: this.$t('Correo electrónico'),
                    value: 'email',
                    sortable: false
                }
            ]
        }
    },
    async mounted() {

        this.preload = true
        this.model = this._.cloneDeep(this.modelSchema)
        await this.fetchCountries()
        this.preload = false
    },
    methods: {
        filterUser() {  

            let model = this._.cloneDeep(this.model)
            model.networkid = this.networkid
            this.loadingBtn = true
            this.loading = true
            this.users = []
            this.total = 0

            this.$api(this, (xhr) => {
                xhr.post('/v2/user/filter/list', this.$qs.stringify(model)).then((r) => {

                    this.loadingBtn = false
                    this.loading = false

                    let data = r.data
                    this.users = data.response ? data.data : []
                    this.total = data.response ? data.total : 0
                    
                }).catch(() => {
                    this.loadingBtn = false
                    this.loading = false
                })
            })
        },
        filterUserPagination() {

            let model = this._.cloneDeep(this.model)
            model.networkid = this.networkid
            model.limit = this.pagination.rowsPerPage
            model.offset = (this.pagination.page-1)*this.pagination.rowsPerPage

            this.$api(this, (xhr) => {
                xhr.post('/v2/user/filter/list', this.$qs.stringify(model)).then((r) => {

                    this.loadingBtn = false
                    let data = r.data
                    this.users = data.response ? data.data : []

                }).catch(() => {
                    this.loadingBtn = false
                    this.loading = false
                })
            })
        },
        fetchNetwork(){

            this.networks = []
            return new Promise((resolve) => {
                this.$api(this, (xhr) => {
                    xhr.get('/network', {
                        params: {
                            list: 1
                        }
                    }).then((r) => {

                        let data = r.data
                        if(data.response){
                            this.networks = data.data
                        }

                        resolve()

                    }).catch(() => {
                        resolve()
                    })
                })
            })
        },
        fetchCountries(){

            this.countries = []
            return new Promise((resolve) => {
                this.$api(this, (xhr) => {
                    xhr.get('/country', {
                        params: {
                            filter: JSON.stringify({
                                status: 1
                            })
                        }
                    }).then((r) => {

                        let data = r.data
                        if(data.response){
                            this.countries = data.data
                        }

                        resolve()

                    }).catch(() => {
                        resolve()
                    })
                })
            })
        },
        fetchState(){

            this.states = []
            return new Promise((resolve) => {
                this.$api(this, (xhr) => {
                    xhr.get('/state', {
                        params: {
                            countryid: this.model.countryid,
                            filter: JSON.stringify({
                                status: 1
                            })
                        }
                    }).then((r) => {

                        let data = r.data
                        if(data.response){
                            this.states = data.data
                        }
                        resolve()
                    }).catch(() => {
                        resolve()
                    })
                })
            })
        },
        fetchCity(){

            this.cities = []
            return new Promise((resolve) => {
                this.$api(this, (xhr) => {
                    xhr.get('/city', {
                        params: {
                            stateid: this.model.stateid,
                            filter: JSON.stringify({
                                status: 1
                            })
                        }
                    }).then((r) => {

                        let data = r.data
                        if(data.response){
                            this.cities = data.data
                        }
                        resolve()
                    }).catch(() => {
                        resolve()
                    })
                })
            })
        }
    }
}
</script>