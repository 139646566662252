<template lang="pug">
    div
        v-snackbar(
        :timeout="3000"
        :top="true"
        :right="true"
        :color="saveColor"
        v-model="saveMsj")
            | {{ saveText }}
            v-btn(flat dark @click.native="saveMsj = false") {{ $t('cerrar') }}
        v-dialog(lazy max-width="900px" v-model="modal" persistent)
            v-card
                v-card-title 
                    .btns.btns-right
                        v-btn(icon color="secondary" small @click.native="modal=false")
                            v-icon close
                    h3.full-width {{ $t('Enviar WhatsApp a un grupo de personas') }}
                v-card-text
                    template(v-if="preload")
                        h3 {{$t('Cargando')}}..
                    template(v-else)
                        p: i {{ $t('Enviar mensajes a WhatsApp de manera segmentada') }}        
                        v-form
                            v-layout(row wrap)
                                v-flex(xs12)
                                    v-select.mb-3(
                                        v-model="model.networkid"
                                        :items='networks'
                                        item-text="name"
                                        item-value="uuid"
                                        :label='$t("Red")'
                                        append-icon="keyboard_arrow_down" 
                                        autocomplete
                                        :filter="vFilter"
                                        :readonly="Object.keys(current).length ? true : false"
                                    )
                                v-flex(xs12)
                                    h4.my-2 {{$t('Segmentación')}}
                                v-flex(xs12 sm6)
                                    v-select.mb-3(
                                        v-model="model.countryid"
                                        :items='countries'
                                        item-text="name"
                                        item-value="uuid"
                                        :label='$t("País")'
                                        append-icon="keyboard_arrow_down" 
                                        autocomplete
                                        :filter="vFilter"
                                        @input="model.stateid='',model.cityid='',states=[],cities=[],fetchState()"
                                        :clearable="!Object.keys(current).length"
                                        :readonly="Object.keys(current).length ? true : false"
                                    )
                                v-flex(xs12 sm6)
                                    v-select.mb-3(
                                        v-model="model.stateid"
                                        :items='states'
                                        item-text="name"
                                        item-value="uuid"
                                        :label='$t("Estado/Departamento")'
                                        append-icon="keyboard_arrow_down" 
                                        autocomplete
                                        :filter="vFilter"
                                        @input="model.cityid='',cities=[],fetchCity()"
                                        :clearable="!Object.keys(current).length"
                                        :readonly="Object.keys(current).length ? true : false"
                                    )
                                v-flex(xs12 sm6)
                                    v-select.mb-3(
                                        v-model="model.cityid"
                                        :items='cities'
                                        item-text="name"
                                        item-value="uuid"
                                        :label='$t("Ciudad")'
                                        append-icon="keyboard_arrow_down" 
                                        autocomplete
                                        :filter="vFilter"
                                        :clearable="!Object.keys(current).length"
                                        :readonly="Object.keys(current).length ? true : false"
                                    )
                                v-flex(xs12 sm6)
                                    v-select.mb-3(
                                        v-model="model.gender"
                                        :items='gender'
                                        item-text="text"
                                        item-value="id"
                                        :label='$t("Género")'
                                        append-icon="keyboard_arrow_down" 
                                        autocomplete
                                        :filter="vFilter"
                                        :clearable="!Object.keys(current).length"
                                        :readonly="Object.keys(current).length ? true : false"
                                    )
                                v-flex(xs12 sm6)
                                    v-select.mb-3(
                                        v-model="model.age"
                                        :items='ages'
                                        item-text="text"
                                        item-value="id"
                                        :label='$t("Rango de edad")'
                                        append-icon="keyboard_arrow_down",
                                        :filter="vFilter"
                                        autocomplete
                                        :clearable="!Object.keys(current).length"
                                        :readonly="Object.keys(current).length ? true : false"
                                    )
                                v-flex(xs12)
                                v-flex(xs12)
                                    h4.my-2 {{$t('Exclusión de usuarios')}}
                                    p(v-if="!Object.keys(current).length"): i {{ $t('Filtre y seleccione los usuarios que quiere excluir de la segmentación') }}
                                v-flex(xs12 v-if="!model.networkid")
                                    p {{ $t('Por favor seleccione una red') }}
                                template(v-else)
                                    v-flex(xs12 v-if="!Object.keys(current).length")
                                        select-user-group(:networkid="model.networkid" :selected.sync="model.excludedUsers")
                                    v-flex(xs12)
                                        h4.my-2 {{$t('Usuarios a excluir')}}
                                        p: i {{ $t('Estos son los usuarios que van a excluirse según la segmentación') }}
                                    v-flex(xs12 v-if="model.excludedUsers.length")
                                        v-data-table(
                                            :headers="headers"
                                            :items="model.excludedUsers"
                                            item-key="uuid"
                                            :pagination.sync='pagination'
                                            :rows-per-page-items="$root.rowsPageItems"
                                            :rows-per-page-text="$root.rowsPageText"
                                            style="max-height:500px;overflow-x:scroll;"
                                        )
                                            template(slot="items" slot-scope="props")
                                                tr
                                                    td {{ props.item.fullnames }}
                                                    td {{ props.item.email }}
                                                    td(v-if="!Object.keys(current).length")
                                                        v-icon.cur-p(@click="model.excludedUsers.splice(props.index, 1)" color="primary") close
                                v-flex(xs12)
                                v-flex(xs12 sm6)
                                    h4.my-2 {{$t('Mensaje de WhatsApp')}}
                                    v-text-field(multi-line required v-model="model.message" :readonly="Object.keys(current).length ? true : false")
                                v-flex(xs12 sm6)
                                    h4.my-2 {{$t('Vista previa')}}
                                    whatsapp-preview(:text="model.message")
                v-card-actions
                    .btns.btns-right
                        v-btn(color="secondary" small @click="modal = false") {{ $t('Cancelar') }}
                        v-btn(v-if="!preload && !Object.keys(current).length" color="primary" :loading="loadingBtn" :disabled="loadingBtn" small @click="save") {{$t('Enviar')}}
</template>

<script>

import fn from 'mixins/fn'
import SelectUserGroup from 'components/shared/SelectUserGroup'

export default {
    components: {
        SelectUserGroup
    },
    mixins: [fn],
    data() {
        return {
            modal     : false,
            saveMsj   : false,
            saveColor : '',
            saveText  : '',
            preload   : true,
            countries  : [],
            states     : [],
            cities     : [],
            modelSchema: {
                networkid : '',
                message   : '',
                countryid : '',
                stateid   : '',
                cityid    : '',
                gender    : '',
                age       : '',
                excludedUsers: []
            },
            model: {},
            loadingBtn: false,
            current: {},
            networks: [],
            pagination: {
                page: 1,
                rowsPerPage: 100
            },
        }
    },
    computed: {
        headers() {
            return [
                {
                    text: this.$t('Nombres'),
                    value: 'fullnames',
                    sortable: false
                },
                {
                    text: this.$t('Correo electrónico'),
                    value: 'email',
                    sortable: false
                },
                {
                    text: this.$t('Acciones'),
                    sortable: false
                }
            ]
        },
        totalExcludedUsers() {
            return this.model.excludedUsers.length   
        }
    },
    methods: {
        async open(item = {}) {

            this.model = this._.cloneDeep(this.modelSchema)
            this.modal = true
            this.current = {}
            this.excludedUsers = []

            if (item.hasOwnProperty('uuid')) {

                this.current = item
                this.preload = true

                let data = await this.fetchDetail(item)
                await this.fetchNetwork()
                await this.fetchCountries()

                this.model = this._.cloneDeep(data)
                await this.fetchState()
                await this.fetchCity()

            } else {
                await this.fetchNetwork()
                await this.fetchCountries()
            }

            this.preload = false
        },
        save() {

            let post = this._.cloneDeep(this.model)
            post.excluded_users = JSON.stringify(this.model.excludedUsers.map(e => e.uuid))
            if (post.hasOwnProperty('excludedUsers')) {
                delete post.excludedUsers
            }

            this.loadingBtn = true

            this.$api(this, (xhr) => {
                xhr.post('/v2/whatsapp/conversation/batch', this.$qs.stringify(post)).then((r) => {

                    let data = r.data
                    this.loadingBtn = false
                    this.saveMsj    = true
    
                    if(data.response){
                        this.saveColor = 'success'
                        this.modal = false
                        this.$emit('success')
                    }else{
                        this.saveColor = 'error'
                    }

                    this.saveText = this.$t(data.message)

                }).catch(() => {

                    this.loadingBtn = false
                    this.saveMsj    = true
                    this.saveColor  = 'error'
                    this.saveText   = this.$t('Ha ocurrido un error')
                })
            })
        },
        fetchNetwork(){

            this.networks = []
            return new Promise((resolve) => {
                this.$api(this, (xhr) => {
                    xhr.get('/network', {
                        params: {
                            list: 1
                        }
                    }).then((r) => {

                        let data = r.data
                        if(data.response){
                            this.networks = data.data
                        }

                        resolve()

                    }).catch(() => {
                        resolve()
                    })
                })
            })
        },
        fetchCountries(){

            this.countries = []
            return new Promise((resolve) => {
                this.$api(this, (xhr) => {
                    xhr.get('/country', {
                        params: {
                            filter: JSON.stringify({
                                status: 1
                            })
                        }
                    }).then((r) => {

                        let data = r.data
                        if(data.response){
                            this.countries = data.data
                        }

                        resolve()

                    }).catch(() => {
                        resolve()
                    })
                })
            })
        },
        fetchState(){

            this.states = []
            return new Promise((resolve) => {
                this.$api(this, (xhr) => {
                    xhr.get('/state', {
                        params: {
                            countryid: this.model.countryid,
                            filter: JSON.stringify({
                                status: 1
                            })
                        }
                    }).then((r) => {

                        let data = r.data
                        if(data.response){
                            this.states = data.data
                        }
                        resolve()
                    }).catch(() => {
                        resolve()
                    })
                })
            })
        },
        fetchCity(){

            this.cities = []
            return new Promise((resolve) => {
                this.$api(this, (xhr) => {
                    xhr.get('/city', {
                        params: {
                            stateid: this.model.stateid,
                            filter: JSON.stringify({
                                status: 1
                            })
                        }
                    }).then((r) => {

                        let data = r.data
                        if(data.response){
                            this.cities = data.data
                        }
                        resolve()
                    }).catch(() => {
                        resolve()
                    })
                })
            })
        },
        fetchDetail(item){
            return new Promise((resolve) => {
                this.$api(this, (xhr) => {
                    xhr.get('/v2/whatsapp/conversation/batch/' + item.uuid).then((r) => {
                        let data = r.data
                        resolve(data.response ? data.data : {})
                    }).catch(() => {
                        resolve({})
                    })
                })
            })
        },
    }
}

</script>