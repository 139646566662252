import config from 'src/config'
const WS_WHATSAPP_URL = config.ws_whatsapp_url
export default {
    data() {
        return {
            ws_whatsapp: null
        }
    },
    methods: {
        connect_ws_whatsapp(userid, message) {

            let ws = new WebSocket(WS_WHATSAPP_URL + userid)
            this.ws_whatsapp = ws
            this.userid = userid

            this.ws_whatsapp.onmessage = message      
        }
    },
    beforeRouteLeave(to, from, next) {
        if (this.ws_whatsapp) {
            this.ws_whatsapp.close()
            this.ws_whatsapp = null
        }
        next()
    }
}