<template lang="pug">
    div
        v-snackbar(
        :timeout="3000"
        :top="true"
        :right="true"
        :color="saveColor"
        v-model="saveMsj")
            | {{ saveText }}
            v-btn(flat dark @click.native="saveMsj = false") {{ $t('cerrar') }}
        v-dialog(lazy max-width="900px" v-model="modal" persistent)
            v-card
                v-card-title 
                    .btns.btns-right
                        v-btn(icon color="secondary" small @click.native="modal=false")
                            v-icon close
                    h3.full-width {{ $t('Enviar WhatsApp a nuevos usuarios') }}
                v-card-text
                    template(v-if="preload")
                        h3 {{$t('Cargando')}}..
                    template(v-else)
                        v-form
                            v-layout(row wrap)
                                v-flex(xs12 sm6)
                                    h4.my-2 {{$t('Mensaje de WhatsApp')}}
                                    v-text-field(multi-line required v-model="model.message" :readonly="Object.keys(current).length ? true : false")
                                v-flex(xs12 sm6)
                                    h4.my-2 {{$t('Vista previa')}}
                                    whatsapp-preview(:text="model.message")
                v-card-actions
                    .btns.btns-right
                        v-btn(color="secondary" small @click="modal = false") {{ $t('Cancelar') }}
                        v-btn(v-if="!preload" color="primary" :loading="loadingBtn" :disabled="loadingBtn" small @click="save") {{$t('Enviar')}}
</template>

<script>

import fn from 'mixins/fn'
export default {
    mixins: [fn],
    data() {
        return {
            modal     : false,
            saveMsj   : false,
            saveColor : '',
            saveText  : '',
            preload   : true,
            countries  : [],
            states     : [],
            cities     : [],
            modelSchema: {
                message   : '',
            },
            model: {},
            loadingBtn: false,
            current: {},
        }
    },
    methods: {
        async open(item = {}) {

            this.model = this._.cloneDeep(this.modelSchema)
            this.modal = true
            this.current = {}

            if (item.hasOwnProperty('uuid')) {

                this.current = item
                this.preload = true

                let data = await this.fetchDetail(item)
                this.model = this._.cloneDeep(data)
            }

            this.preload = false
        },
        save() {

            let post = this._.cloneDeep(this.model)
            post.uuid = this.current.uuid
            this.loadingBtn = true

            this.$api(this, (xhr) => {
                xhr.post('/v2/whatsapp/conversation/batch-again', this.$qs.stringify(post)).then((r) => {

                    let data = r.data
                    this.loadingBtn = false
                    this.saveMsj    = true
    
                    if(data.response){
                        this.saveColor = 'success'
                        this.modal = false
                        this.$emit('success')
                    }else{
                        this.saveColor = 'error'
                    }

                    this.saveText = this.$t(data.message)

                }).catch(() => {

                    this.loadingBtn = false
                    this.saveMsj    = true
                    this.saveColor  = 'error'
                    this.saveText   = this.$t('Ha ocurrido un error')
                })
            })
        },
        fetchDetail(item){
            return new Promise((resolve) => {
                this.$api(this, (xhr) => {
                    xhr.get('/v2/whatsapp/conversation/batch/' + item.uuid).then((r) => {
                        let data = r.data
                        resolve(data.response ? data.data : {})
                    }).catch(() => {
                        resolve({})
                    })
                })
            })
        }
    }
}

</script>